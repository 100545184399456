import {api} from "@/bootstrap/api";
import {ORDER_STEP_ID_COMPLETE} from "@/bootstrap/config";

export default {
    setAsInProgress(orderId: number) {
        return api(
            'pickOrder',
            {
                data: {
                    batchDetails: [],
                    storedProductDetails: [],
                },
                params: {
                    order_id: orderId,
                }
            }
        )
    },
    setAsPickComplete(orderId: number, packageIdentifier: string) {
        return api(
            'patchOrder',
            {
                data: {
                    package_identifier: packageIdentifier,
                    order_header_step_id: ORDER_STEP_ID_COMPLETE
                },
                params: {
                    order_id: orderId,
                },
            },
        )
    }
}
