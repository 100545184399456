
import Vue from "vue";
import {
  AbandonmentReasonsEnum,
  AbandonmentReasonsI18nMap,
} from "@/utilities/AbandonmentReasons";

export default Vue.extend({
  name: "AbandonDialog",
  data: () => ({
    loading: false,
    abandonmentReasons: AbandonmentReasonsI18nMap,
    confirmModal: false,
    reasonClicked: null as AbandonmentReasonsEnum | null,
  }),
  props: {
    wavePick: {
      type: Boolean,
      default: false,
    },
    hubooSku: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    this.reasonClicked = null;
  },
  computed: {
    goBackButtonText() {
      return this.wavePick
        ? this.$i18n.t("abandon_order.confirm_dialog.button.go_back_to_list")
        : this.$i18n.t("stop_picking");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showConfirmModal(reason: AbandonmentReasonsEnum | null) {
      if (this.wavePick && !reason) {
        this.$emit("allowOrderExit");
        this.$emit("backToListClicked");
        return;
      }
      this.reasonClicked = reason;
      this.confirmModal = true;
    },
    confirmAbandon(): void {
      this.$emit("allowOrderExit");
      this.$store.dispatch("core/redirect", {
        name: "wave_pick_abandon",
        params: {
          sku: this.hubooSku,
          reason: this.reasonClicked,
        },
      });
      this.reasonClicked = null;
    },
    async abandon(reason: AbandonmentReasonsEnum | null): Promise<void> {
      this.loading = true;
      try {
        this.$emit("allowOrderExit");
        await this.$store.dispatch("picking/abandon", reason);
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
});
