import {LocaleMessage} from "vue-i18n";

export enum AbandonmentReasonsEnum {
    NO_STOCK = 'no_stock',
    NO_PACKAGING = 'no_packaging',
    DAMAGED_STOCK = 'damaged_stock',
}

export type AbandonmentReasonsI18NMapType = {
    id: AbandonmentReasonsEnum,
    label: LocaleMessage
}

export const AbandonmentReasonsI18nMap: Array<AbandonmentReasonsI18NMapType> = [
    {
        id: AbandonmentReasonsEnum.NO_STOCK,
        label: 'no_stock'
    },
    {
        id: AbandonmentReasonsEnum.NO_PACKAGING,
        label: 'no_packaging'
    },
    {
        id: AbandonmentReasonsEnum.DAMAGED_STOCK,
        label: 'damaged_stock'
    },
]
